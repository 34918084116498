import React, { cloneElement, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Field, Formik } from "formik";
import { FormTogglers, NotificationHelper } from "./";
import { useTranslation } from "react-i18next";
import { ksaCountryCode, userTitles } from "../../util/constants";
import OtpStep from "./OtpStep";
import {
  loadStorage,
  currentLanguage,
  limitizeValue,
  removeKSACountryCode
} from "../../util/common";
import { authValidations } from "../../util/validations";
import { useSelector } from "react-redux";
import { UserService } from "../../services";
import { Errors } from "../../util/errorMessage";
import { useAppNavigation } from "../../hooks";

const AuthenticationStep = (props) => {
  const {
    userData,
    setUserData,
    setView,
    globalValues,
    setGlobalValues,
    setShowLoader,
    isUpdating,
    module = "other",
    extendedFieldsConfig
  } = props;
  const componentConfig = {
    appointment: {
      emailRequired: false,
      requiredValues:
        (userData?.firstName && userData?.lastName && !extendedFieldsConfig) || isUpdating,
      headingClass: "text-md-center mt-md-5 mb-0"
    },
    other: {
      emailRequired: true,
      requiredValues: userData?.firstName && userData?.lastName && userData?.email,
      headingClass: "text-md-center mt-5 mb-0"
    }
  };
  const language = currentLanguage();

  const { t } = useTranslation();
  const { localeRoutes } = useAppNavigation();
  const [mobilePhone, setMobilePhone] = useState("");
  const [otpForm, setOtpForm] = useState(false);
  const userState = useSelector((state) => state.user.details);

  const isUserVerified = userState?.id;
  const fieldsDisabled = userState?.firstName && isUserVerified;

  const handleAuthSubmit = async (values) => {
    const phoneNumber = ksaCountryCode + values.phoneNumber;
    const userValues = { userId: userState.id, mobilePhone: phoneNumber, ...values };
    setMobilePhone(phoneNumber);

    if (!isUserVerified) {
      setShowLoader(true);
      try {
        const registerResponse = await UserService.registerUser({
          ...values,
          phoneNumber
        });
        if (registerResponse) {
          setOtpForm(true);
          setShowLoader(false);
          setUserData(userValues);
        }
      } catch (e) {
        setShowLoader(false);
        console.error(e);
        const key = e?.response?.data?.key || "UNEXPECTED_ERROR";
        NotificationHelper.error(t(Errors[key]));
      }
    } else {
      setGlobalValues({ ...globalValues, ...userValues });
      if (extendedFieldsConfig?.submit) extendedFieldsConfig.submit(values, true);
      setView(1);
    }
  };

  return (
    <div className={module + "-auth"}>
      {!otpForm ? (
        <Formik
          enableReinitialize
          initialValues={{
            title: globalValues?.title || userData?.title || "Mr",
            firstName: globalValues?.firstName || userData?.firstName || "",
            lastName: globalValues?.lastName || userData?.lastName || "",
            phoneCode: ksaCountryCode,
            phoneNumber: removeKSACountryCode(userData?.mobilePhone) || "",
            email: globalValues?.email || (module === "other" && userData?.email) || "",
            ...(extendedFieldsConfig?.initialValues || {})
          }}
          validationSchema={() =>
            authValidations({
              validateDetails: true,
              emailRequired: componentConfig[module].emailRequired,
              extendedValidation: extendedFieldsConfig?.validations || {}
            })
          }
          onSubmit={handleAuthSubmit}>
          {({ values, errors, touched, dirty, setFieldValue }) => (
            <Form className="personal-details">
              <div className="container">
                <h4 className={componentConfig[module].headingClass}>
                  {t("estimationRequest.personalDetails")}
                  <span className="required-flag">*</span>
                </h4>
                {userState?.firstName && isUserVerified && !isUpdating && (
                  <p className="my-account-link">
                    {t("scheduleAppointment.myAccountMsg")}{" "}
                    <Link to={localeRoutes.myAccount[language]}>
                      {t("scheduleAppointment.myAccountLink")}
                    </Link>
                  </p>
                )}

                <div className="col-md-10 col-lg-7 col-xl-6 divider mt-4">
                  <div>
                    <Field
                      as="select"
                      id="mrMs"
                      name="title"
                      className="form-control form-select"
                      disabled={fieldsDisabled}>
                      <option value="Mr">{userTitles.Mr[language]}</option>
                      <option value="Ms">{userTitles.Ms[language]}</option>
                    </Field>
                  </div>

                  <div>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder={t("estimationRequest.authFirstName")}
                      className="form-control"
                      disabled={fieldsDisabled}
                    />
                    {errors.firstName && touched.firstName && (
                      <span className="red-color">{t(errors.firstName)}</span>
                    )}
                  </div>

                  <div>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder={t("estimationRequest.authLastName")}
                      className="form-control"
                      disabled={fieldsDisabled}
                    />
                    {errors.lastName && touched.lastName && (
                      <span className="red-color">{t(errors.lastName)}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-10 col-lg-7 col-xl-6 divider">
                  <div className="est-phone-number w-100">
                    <div>
                      <Field
                        type="number"
                        id="phoneCode"
                        name="phoneCode"
                        placeholder={ksaCountryCode}
                        className="form-control"
                        disabled
                      />
                    </div>
                    <div className="w-100">
                      <Field
                        type="number"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder={t("estimationRequest.e.g")}
                        className="form-control"
                        country="sa"
                        pattern="[0-9]*"
                        onInput={limitizeValue}
                        disabled={isUserVerified}
                      />
                      {errors.phoneNumber && touched.phoneNumber && (
                        <span className="red-color">{t(errors.phoneNumber)}</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder={t("estimationRequest.authEmail")}
                      className="form-control"
                      disabled={userState?.email && isUserVerified}
                    />
                    {errors.email && touched.email && (
                      <span className="red-color">{t(errors.email)}</span>
                    )}
                  </div>
                </div>

                {loadStorage("session")?.expired && (
                  <p className="session-expired">{t("loginModal.sessionExpired")}</p>
                )}

                {isUserVerified &&
                  extendedFieldsConfig &&
                  cloneElement(extendedFieldsConfig.component, {
                    values,
                    setFieldValue,
                    errors,
                    touched
                  })}

                <FormTogglers
                  dependentValue={componentConfig[module].requiredValues}
                  dirty={dirty}
                  tabHandler={isUserVerified ? setView : setOtpForm}
                  tab={1}
                  t={t}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <OtpStep
          mobilePhone={mobilePhone}
          setOtpForm={setOtpForm}
          setShowLoader={setShowLoader}
          // setUserData={setUserData}
        />
      )}
    </div>
  );
};

export default React.memo(AuthenticationStep);
