import { currentLanguage as lang } from "./common";
import OtherBranchIcon from "../images/other-branch-icon.svg";
import RecommendedBranchIcon from "../images/recommended-branch-icon.svg";
import { splitCarNumberPlate } from "./carOptionsUtils";
import { Trans } from "react-i18next";

export const getLocalizedOptions = (record) => ({
  value: record._id,
  label: record["value_" + lang()],
  localized: { en: record.value_en, ar: record.value_ar }
});

export const getCarBrandOptions = (brandOptions = [], userCars = []) => {
  const groupedOptions = [
    {
      label: <Trans i18nKey="scheduleAppointment.allCars" />,
      options: brandOptions.map((brand) => {
        return getLocalizedOptions(brand);
      })
    }
  ];

  userCars.length &&
    groupedOptions.unshift({
      label: <Trans i18nKey="scheduleAppointment.recentCars" />,
      options: userCars.map((car, i) => {
        return {
          label: car?.carNickName || car?.brand?.["value_" + lang()],
          value: car?.brand?._id,
          model: car?.product?.["value_" + lang()],
          year: car?.modelYear,
          plateNumber: splitCarNumberPlate(car?.carNumberPlate)[0],
          plateChar: splitCarNumberPlate(car?.carNumberPlate)[1],
          index: i
        };
      })
    });

  return groupedOptions;
};

export const getServiceOptions = (
  { _id, name, cars, isUploadImages, isExpressAllowed, advanceBookingDays },
  appointment,
  selectedCar
) => {
  if (cars?.length && !cars.includes(selectedCar)) return null;

  const value = {
    label: name?.["value_" + lang()],
    value: JSON.stringify({
      id: _id,
      nameEn: name?.value_en,
      nameAr: name?.value_ar,
      slot: appointment?.services?.[0]?.slot,
      isUploadImages,
      isExpressAllowed,
      advanceBookingDays
    })
  };
  return value;
};

export const getCitiesOptions = (city) => {
  const branchCities = city?.map((city) => ({
    label: city[lang()],
    value: city.en
  }));
  return branchCities;
};

export const getBranchesInCity = ({
  branches,
  value,
  isCollectionAndDelivery = false,
  markerPosition,
  settings
}) => {
  const branchesInCity = [];
  let isNearestBranch = false;
  branches.forEach((item) => {
    if (item.city === value || item.cityNameAr === value) {
      if (isCollectionAndDelivery) {
        isNearestBranch = toCheckNearestBranch({
          selectedLocation: markerPosition,
          radius: settings?.cadRadius,
          branch: item
        });
      }

      if (!isNearestBranch && isCollectionAndDelivery) return;
      branchesInCity.push({
        id: item._id,
        name: item.name,
        shopTimings: item.shopTimings,
        address: item.address,
        addressArabic: item.addressArabic,
        location: item.location,
        addressLink: item.addressLink,
        activateForAddOnFlow: item.activateForAddOnFlow,
        activateForExpress: item.activateForExpress,
        isRecommended: item.isRecommended,
        city: item?.city
      });

      // if (isReschedule || isQuotation || isSubscription)
      //   setCityOptions([{ en: item.city, ar: item.cityNameAr }]);
    }
  });

  return branchesInCity;
};

const toCheckNearestBranch = ({ selectedLocation, branch, radius }) => {
  const { lat: cLat, lng: cLng } = selectedLocation["CollectionAddress"];
  const { lat: dLat, lng: dLng } = selectedLocation["DeliveryAddress"];
  const branchLong = branch?.location?.coordinates[0];
  const branchLat = branch?.location?.coordinates[1];

  const collectionDistance = calculateDistance(cLat, cLng, branchLat, branchLong);
  const deliveryDistance = calculateDistance(dLat, dLng, branchLat, branchLong);

  return collectionDistance <= radius && deliveryDistance <= radius;
};

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
}

export const getProviderOptions = (providers, t) => {
  const groupedOptions = [
    {
      label: t("scheduleAppointment.otherBranches"),
      options: []
    },
    {
      label: t("scheduleAppointment.recommendedBranches"),
      options: []
    }
  ];

  providers.forEach((p) => {
    const option = {
      label: (
        <>
          <img
            src={p.isRecommended ? RecommendedBranchIcon : OtherBranchIcon}
            className="branch-icon"
          />{" "}
          {p.name["value_" + lang()]}
        </>
      ),
      value: p.name.value_en,
      id: p.id,
      ...p
    };
    if (!p.isRecommended) groupedOptions[0].options.push(option);
    else groupedOptions[1].options.push(option);
  });

  return groupedOptions;
};

export const getCarOwnByOptions = (data) =>
  data.map(({ _id, name }) => ({ value: _id, label: name["value_" + lang()] }));

export const getBookingForOptions = (data) =>
  data.map(({ _id, name }) => ({ value: _id, label: name["value_" + lang()] }));

export const formatGroupLabel = (data) => {
  return (
    <div>
      <span>{data.label}</span>
    </div>
  );
};

export const formatOptionLabel = (option) => <span id={option.value}>{option.label}</span>;

let focusedValue;
// Stop looping focus on dropdown when using arrow up and down key
export const forFocus = (e) => {
  focusedValue = e.focused.value;
};

export const forKey = (e) => {
  const opts = document.querySelectorAll(
    `#${e.target.id.replace("input", "listbox")} .select__option`
  );

  if (e.key === "ArrowUp" && opts[0]?.children[0].id === focusedValue) e.preventDefault();
  if (e.key === "ArrowDown" && opts[opts.length - 1]?.children[0].id === focusedValue)
    e.preventDefault();
};

export const getQuoteServiceOptions = (data) => {
  const options = data?.map((service) => ({
    label: service.name?.["value_" + lang()],
    value: service.name?.["value_" + lang()],
    ...service
  }));
  return options;
};

export const getCityLabel = (cityOptions, city) =>
  cityOptions.find(({ en, ar }) => en === city || ar === city)?.[lang()] || city;
export const getProviderLabel = (branches, provider) =>
  branches.find(({ name }) => name?.value_en === provider || name?.value_ar === provider)?.name[
    "value_" + lang()
  ];
