import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, formatAmount, getFormatedDate } from "../../util/common";
import { Button, Icon, SharedUrlButtons, TamaraWidget } from "./";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { calculateDiscount } from "../../util/addonsUtils";
import { useAppNavigation } from "../../hooks";

const AddOnDetailsModal = ({ show, close, selected, handleCart, doExist, setLoader }) => {
  const { t } = useTranslation();
  const { localeRoutes } = useAppNavigation();

  const lang = currentLanguage();
  const { type, addon } = selected;
  const isProduct = type === "products";
  const isRecommendedOffer = type === "recommendedOffers";
  const quantity = addon?.totalRemainingQuantity;

  const [selectedMedia, setSelectedMedia] = useState({ type: "image", media: "" });

  const handleImageLoad = () => setLoader(false);

  const handleClose = () => {
    if (isRecommendedOffer) setSelectedMedia({ type: "image", media: "" });
    close();
  };

  return (
    <>
      {addon && (
        <Modal show={show} onHide={handleClose} centered size="xl">
          <Modal.Body className="addon-desc-modal">
            <div className="close-icon">
              <Icon iconName="xmark" onClick={handleClose} />
            </div>

            <div className="content">
              {selectedMedia.type !== "video" ? (
                <img
                  src={
                    GET_MEDIA_URL +
                    (selectedMedia.media ||
                      addon?.images?.[1]?.value ||
                      addon["banner_" + lang] ||
                      addon["image_" + lang] ||
                      addon.image)
                  }
                  onLoad={handleImageLoad}
                />
              ) : (
                <video controls className="play-video">
                  <source src={GET_MEDIA_URL + addon?.video} type="video/mp4" />
                </video>
              )}
              {isRecommendedOffer && (
                <div className="recommended-offers-images">
                  {addon?.images?.map((image, index) => {
                    if (image?.key !== "imageOne") {
                      return (
                        <img
                          src={GET_MEDIA_URL + image?.value}
                          onLoad={handleImageLoad}
                          key={index}
                          onClick={() => setSelectedMedia({ type: "image", media: image?.value })}
                        />
                      );
                    }
                  })}
                  {addon?.video ? (
                    <div
                      className="video-wrapper"
                      onClick={() => setSelectedMedia({ type: "video", media: addon.video })}>
                      <div className="video-container">
                        <video controls>
                          <source src={GET_MEDIA_URL + addon?.video} type="video/mp4" />
                        </video>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <h2>{addon.name["value_" + lang]}</h2>
              {isProduct && (
                <>
                  <div className="bullets">
                    <p>
                      {t("addons.categoryLabel")} <span>{addon.category["value_" + lang]}</span>
                    </p>
                    <p>
                      {t("addons.productLabel")} <span>{addon.product["value_" + lang]}</span>
                    </p>
                  </div>

                  {/* addon pricing details */}
                  <div className="addon-price-container">
                    {addon?.oldPrice && (
                      <>
                        <span className="addon-price-label">{t("shop.was")}:</span>
                        <span className="addon-price-was">
                          {formatAmount(addon?.oldPrice)} {t("addons.currency")}
                        </span>
                      </>
                    )}
                    <span className="addon-price-label">{t("shop.now")}:</span>
                    <span className="addon-price-now">
                      {formatAmount(addon?.price)} {t("addons.currency")}
                    </span>
                    {addon?.oldPrice && (
                      <>
                        <span className="addon-price-label">{t("shop.discount")}:</span>
                        <span className="addon-price-discount">
                          {calculateDiscount(addon?.oldPrice, addon?.price)} {t("addons.currency")}
                        </span>
                      </>
                    )}
                  </div>

                  {quantity <= 0 ? (
                    <p className="out-of-stock mt-3 mb-5">{t("shop.outOfStock")}</p>
                  ) : null}

                  {/* {addon?.price && <TamaraWidget type="info" parentClass="mt-4" price={addon?.price} />} */}

                  {/* share icons section */}
                  <h5 className="mt-4">{t("shop.shareVia")}</h5>
                  <div className="addon-share-container">
                    {/* todo branch id hard coded changed into dynamic if eshop branch id changed */}
                    <SharedUrlButtons
                      sharedUrl={`${window.location.origin}${localeRoutes.shopProduct[lang]}/${addon?._id}/63c10f6aa920ea32701f0b7a`}
                    />
                  </div>
                </>
              )}

              <div className="addon-desc">
                <p
                  className="shortDesc"
                  dangerouslySetInnerHTML={{
                    __html: addon.detail?.["value_" + lang] || addon.description?.["value_" + lang]
                  }}
                />
              </div>
              {!isRecommendedOffer && (
                <div className="cart-btn">
                  {type === "offers" || quantity > 0 ? (
                    <Button
                      type="blue-roundedRight"
                      action={() => handleCart(addon, type)}
                      customize={doExist(addon, type).exist && "remove"}
                      disabled={quantity <= 0}>
                      {doExist(addon, type).exist
                        ? t("addons.removeFromCart")
                        : t("addons.addToCart")}
                      <Icon iconName="cart" />
                    </Button>
                  ) : null}

                  {quantity > 0 && (
                    <p className="bullet mb-0">
                      {t("addons.availableOn")}{" "}
                      <span>
                        {getFormatedDate(
                          moment().add(
                            addon?.advanceBookingDays + 1,
                            // || addon.serviceType?.advanceBookingDays + 1,
                            "days"
                          ),
                          "MMMM DD, YYYY"
                        )}
                      </span>
                    </p>
                  )}

                  {type === "offers" && (
                    <p className="bullet mb-0">
                      {t("addons.validTill")}{" "}
                      <span>{getFormatedDate(addon.endDate, "MMMM DD, YYYY")}</span>
                    </p>
                  )}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default React.memo(AddOnDetailsModal);
