import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_MEDIA_URL } from "../../config/webService";
import { currentLanguage, formatAmount, getFormatedDate } from "../../util/common";
import { useAppNavigation } from "../../hooks";
import { Button, RiyalIcon } from "./";
import CampaignViewModal from "./CampaignViewModal";
// import TREE from "../../images/tree.png";

// const PriceDesignForCampaign = () => (
//   <div className="gap-2 d-flex justify-content-center">
//     {[0, 1, 2].map((i) => {
//       return (
//         <img key={i} src={TREE} style={{ width: "16px", height: "16px", objectFit: "contain" }} />
//       );
//     })}
//   </div>
// );

const FlipCard = (props) => {
  const {
    type = "offers",
    offer = {},
    setLoading,
    availOfferButton: AvailOfferButton,
    openNonCampaignDetailsModal
  } = props;

  const isAddonOrUpsell = type === "appointment" || type === "upsell";
  const quotationService = offer.isQuotationService;

  const parentContainerClassNames = {
    general: `col-md-6 col-lg-6 flip-card  ${quotationService ? "flip-card-quote" : ""} `,
    offers: "col-xl-3",
    appointment: "col-xl-3",
    upsell: "col-xl-4"
  };

  const { t } = useTranslation();
  const lang = currentLanguage();
  const { toUrlWithData, localeRoutes, toRequestAQuote } = useAppNavigation();
  const [showModal, setShowModal] = useState(false);

  const goToBooking = async () => {
    if (quotationService) {
      toRequestAQuote(offer.slugUrl);
    } else {
      toUrlWithData(localeRoutes.bringIt[lang], {
        service: { id: offer.id },
        offer: true
      });
    }
  };

  const handleModal = () => {
    if (!offer.isCampaignActive) return openNonCampaignDetailsModal(offer, "offers");

    setLoading(true);
    setShowModal(true);
  };

  return (
    <>
      {!offer ? null : (
        <>
          <div className={parentContainerClassNames.general + parentContainerClassNames[type]}>
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div
                  className="image"
                  style={{
                    background: `url(${GET_MEDIA_URL + offer["image_" + lang]})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "300px"
                  }}>
                  <h1>{offer.name["value_" + lang]}</h1>
                  {offer.isCampaignActive && !isAddonOrUpsell && (
                    <>
                      <div className="expiry-date">
                        <span>
                          {t("services.validity")}:{" "}
                          {getFormatedDate(offer.campaignEndDate, "YYYY-MM-DD")}
                        </span>
                      </div>

                      <div className="ribbon-wrapper">
                        <div className="ribbon-tag"> {t("services.ribbonText")}</div>
                      </div>
                    </>
                  )}
                </div>
                {offer.isCampaignActive ? (
                  <div className="details">
                    {/* {!isAddonOrUpsell && <PriceDesignForCampaign />} */}
                    <span>
                      <RiyalIcon />
                      {formatAmount(offer.campaignPrice)}
                      <br />
                      <del>{formatAmount(offer.price)}</del>
                    </span>
                    {/* {!isAddonOrUpsell && <PriceDesignForCampaign />} */}
                  </div>
                ) : (
                  offer.price > 0 && (
                    <div className="details">
                      <span>
                        <RiyalIcon />
                        {formatAmount(offer.price)}
                      </span>
                    </div>
                  )
                )}
              </div>
              <div className="flip-card-back">
                <span className="name">{offer.name["value_" + lang]}</span>
                <p
                  className="shortDesc"
                  dangerouslySetInnerHTML={{
                    __html: offer.detail?.["value_" + lang] || offer.description?.["value_" + lang]
                  }}
                />
                {(offer.isCampaignActive || isAddonOrUpsell) && (
                  <Button
                    type={!isAddonOrUpsell ? "small-roundedLeft" : "blue-roundedLeft"}
                    customize="m-0 mt-4"
                    action={handleModal}>
                    {t("services.seeMore")}
                  </Button>
                )}
              </div>
            </div>
            <div className="details">
              {AvailOfferButton || (
                <Button className="btn" action={goToBooking}>
                  {quotationService ? t("quotations.getQuoteBtn") : t("offer.buyAndBook")}
                </Button>
              )}
            </div>
          </div>

          {offer.isCampaignActive && (
            <CampaignViewModal
              {...{
                show: showModal,
                onCancel: () => setShowModal(false),
                offer,
                setLoading,
                goToBooking,
                AvailOfferButton
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(FlipCard);
