import Loader from "./loader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import checkedMark from "../../images/checked.svg";
import { generateInvoice } from "../../util/addonsUtils";

const DownloadInvoice = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { invoiceType, orderId, type } = useParams();

  const handleInvoiceDownload = async () => {
    try {
      setLoading(true);
      await generateInvoice({
        id: orderId,
        deposit: invoiceType === "order",
        type,
        check: true,
        fromDownloadInvoice: true,
        refund: invoiceType === "refund"
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleInvoiceDownload();
  }, []);

  return (
    <>
      <Loader show={loading} />
      <div className="position-relative">
        <div className="container success-screen design-props py-5 px-4">
          <div className="col-md-11 col-lg-10 col-xl-8 m-auto text-center">
            <img src={checkedMark} />
            <h2>{t("downloadInvoice.successHeading")}</h2>
            <h4 className="mb-3 mb-md-4">{t("downloadInvoice.para1")}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadInvoice;
