import { CarService } from "../services";

// My account: Getting car brands
export const getCarBrands = async (setBrandOptions, serviceId) => {
  try {
    const brands = await CarService.getBrands({ serviceId });
    if (brands) setBrandOptions(brands);
  } catch (e) {
    console.error(e);
  }
};

// My account: Getting car models
export const getCarModels = async (brand, setModelOptions) => {
  try {
    const models = await CarService.getModels({ brand });
    if (models) {
      setModelOptions(models);
      return models;
    }
  } catch (e) {
    console.error(e);
  }
};

// My account: Getting car model years
export const getCarYears = async (brand, model, setYearOptions) => {
  try {
    const years = await CarService.getYears({ brand, model });
    if (years) setYearOptions(years);
  } catch (e) {
    console.error(e);
  }
};

// My account: Field value setter to update any car
export const fieldValueSetter = (vehiclesFormRef, car, language) => {
  vehiclesFormRef.current.setFieldValue("Brand", car.brand?.["value_" + language]);
  vehiclesFormRef.current.setFieldValue("OtherBrand", car.otherBrand || "");
  vehiclesFormRef.current.setFieldValue("Model", car.product?.["value_" + language]);
  vehiclesFormRef.current.setFieldValue("OtherModel", car.otherModel || "");
  vehiclesFormRef.current.setFieldValue("Year", car.modelYear);
  vehiclesFormRef.current.setFieldValue("plateNumber", splitCarNumberPlate(car.carNumberPlate)[0]);
  vehiclesFormRef.current.setFieldValue("plateChar", splitCarNumberPlate(car.carNumberPlate)[1]);
  vehiclesFormRef.current.setFieldValue("carNickName", car.carNickName);
};

// Global: Format car plate number as needed
export const getFormattedCarNumberPlate = (num) => {
  if (num.includes("-")) return num.toUpperCase();
  return num.slice(0, 4) + "-" + num.slice(4).toUpperCase();
};

// Global: Split car plate number into numbers and characters
export const splitCarNumberPlate = (num = "") => [
  num.split("-")[0] || "",
  num.split("-")[1]?.toUpperCase() || ""
];

// Schedule Appointment: Create selected car payload
export const getSelectedCarPayload = (car) => {
  if (!car) return {};

  return {
    brand: {
      en: car.brand?.value_en || car.make?.value_en || car.brand,
      ar: car.brand?.value_ar || car.make?.value_ar || car.brand
    },
    model: {
      en: car.product?.value_en || car.model?.value_en || car.model,
      ar: car.product?.value_ar || car.model?.value_ar || car.model
    },
    otherBrand: car.otherBrand,
    otherModel: car.otherModel,
    year: car.modelYear || car.year,
    carNumberPlate: getFormattedCarNumberPlate(car.carNumberPlate || car.licencePlate),
    carNickName: car.carNickName
  };
};
