import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { currentLanguage, isLtr } from "../../util/common";
import { careemLogoSet, logoSet } from "../../util/constants";
import { Button, Icon, RiyalIcon, NotificationHelper } from "../shared";
import { UserService } from "../../services";
import Loader from "../shared/loader";
import { isEmpty } from "lodash";
import { useCopyToClipboard } from "../../hooks";

const GenerateCareemCode = () => {
  const { t } = useTranslation();
  const lang = currentLanguage();

  const [loading, setLoading] = useState(false);
  const [careemCode, setCareemCode] = useState("");

  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const generateCareemCode = async () => {
    try {
      setLoading(true);
      const { statusCode, response } = await UserService.generateCareemCode();
      if (statusCode === 200) {
        setCareemCode(response);
      }
    } catch (err) {
      console.error(err);
      NotificationHelper.error(t("careemCode.notFound"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`${isLtr() ? "ltr" : "rtl"}`}>
      <Loader show={loading} />

      <div className="track-form-box careem-code-wrapper">
        <div className="careem-code-logo-wrapper">
          <img src={logoSet[lang]} alt="autohub-logo" width="100%" height="100%" />
          <Icon iconName="collab" className="collab-icon" />
          <img src={careemLogoSet[lang]} alt="autohub-logo" width="100%" height="100%" />
        </div>
        <div>
          <Formik
            initialValues={{
              careemCode: careemCode || ""
            }}
            onSubmit={generateCareemCode}>
            {() => (
              <Form className="p-width">
                <h4 className="section-heading title_form mb-2 text-center font-size22">
                  {t("careemCode.heading")}
                </h4>
                <p className="text-center"></p>
                <div className="row">
                  <div className="col-md-8 col-lg-6 m-auto">
                    <div className="phone-no-wrap careem-inputs">
                      {!isEmpty(careemCode) && (
                        <div className="input-group">
                          <Field
                            type="text"
                            id="careemCode"
                            name="careemCode"
                            className="form-control border-left-0"
                            value={careemCode}
                            disabled
                          />
                          <Button
                            type="blue-roundedRight"
                            action={() => copyToClipboard(careemCode)}>
                            <Icon iconName={isCopied ? "check" : "copy"} className="icon-size" />
                          </Button>
                        </div>
                      )}
                    </div>
                    {isEmpty(careemCode) && (
                      <div className="generate-code-btn">
                        <Button btnType="submit" type="blue-roundedRight" action={() => {}}>
                          {t("careemCode.generate")}
                        </Button>
                      </div>
                    )}
                    <div className="mt-4 careem-terms">
                      <h4 className="main-heading text-center"> {t("careemCode.termsHeading")}</h4>
                      <ul>
                        <li>
                          {t("careemCode.point1")}
                          <RiyalIcon />
                          {t("careemCode.point1a")}
                        </li>
                        <li>{t("careemCode.point2")}</li>
                        <li>{t("careemCode.point3")}</li>
                        <li>{t("careemCode.point4")}</li>
                      </ul>
                    </div>
                </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default GenerateCareemCode;
