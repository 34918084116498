import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { hotjar } from "react-hotjar";
import { Button, Loader, Icon, LoginModal } from "../shared";
import { AppServices, UserService, CampaignServices } from "../../services";
import {
  scrollPageTop,
  camelize,
  navigationWithEvents,
  logEvent,
  currentLanguage,
  getQueryData
} from "../../util/common";
import { Link, useLocation } from "react-router-dom";
import { contactUs, logoSet } from "../../util/constants";
import { saveMedia } from "../../redux/reducers/contentReducer";
import { useDispatch, useSelector } from "react-redux";
import BottomTabNavigations from "./BottomTabNavigation";
import { vanishDraftValues } from "../../util/addonsUtils";
import { useAppNavigation, useWindowWide } from "../../hooks";
import { saveUser } from "../../redux/reducers/userReducer";
import { toggleLoginModalVisibility } from "../../redux/reducers/componentReducer";

const Header = () => {
  const { t } = useTranslation();
  const lang = currentLanguage();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const wide = useWindowWide();
  const { user: userData, content, component } = useSelector((state) => state);
  const {
    toOffers,
    toContactUs,
    toTrackYourProgress,
    toEstimationWelcome,
    toShop,
    toBringIt,
    localeRoutes,
    toQuotations
  } = useAppNavigation();

  const user = userData.details;
  const pages = content.pages || [];
  const inOffer = pathname.includes(localeRoutes.offers[lang]);
  const inKaizen = pathname.includes(localeRoutes.kaizen[lang]);
  const knowledgeHub = pathname.includes(localeRoutes.knowledgeHub[lang]);

  const [navShow, setNavShow] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  // const [services, setServices] = useState([]);
  // const [offers, setOffers] = useState([]);
  // const [isOfferHasNewDeal, setIsOfferHasNewDeal] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);

  const slicedServiceCategories =
    serviceCategories?.length > 5 ? serviceCategories?.slice(0, 4) : serviceCategories;

  useEffect(() => {
    getAppData();
    hotjar.initialize(2632337, 4.0);
  }, []);

  useEffect(() => {
    scrollPageTop();
    // if (wide < 991 && navShow) setNavShow(false); // Only update navShow if it's currently true
    if (wide < 991) setNavShow(false);
    vanishDraftValues();
  }, [pathname]);

  const getAppData = async () => {
    setProcessing(true);
    try {
      await getUserNotifications();

      const media = await AppServices.getMedia();
      dispatch(saveMedia(media));

      // const campaigns = await CampaignServices.getCampaign();
      // if (campaigns) {
      //   setOffers(campaigns?.filter((offer) => offer?.offerType !== "prePaid"));
      //   toCheckIfOneOfferIsNewDeal(campaigns);
      // }
      const categories = await CampaignServices.getServiceCategorizes({ isPrePaid: true });
      // categories.unshift({ _id: "", name: { value_en: "Offers", value_ar: "العروض" } });
      setServiceCategories(categories);

      // const services = await AutohubServices.getServices({ filterKey: "isShowInService" });
      // if (services) {
      //   const servicesCopy = services
      //     .flatMap((service) => service?.services || [])
      //     .filter((service) => !service?.isComingSoon);

      //   setServices(servicesCopy.slice(0, 6));
      // } else setServices([]);
    } catch (e) {
      console.error(e);
    }
    setProcessing(false);
  };

  const getUserNotifications = async () => {
    const hyperpayId = getQueryData("id");
    if (!user.id || !user.notifications?.isCNDDocsSubmitted || hyperpayId) return;
    const { notifications } = await UserService.getUserNotifications();
    dispatch(saveUser({ ...user, notifications }));
  };

  // const toCheckIfOneOfferIsNewDeal = (offers) => {
  //   return setIsOfferHasNewDeal(offers.some((offer) => offer?.isNewDeal === true));
  // };

  return (
    <>
      <Loader show={isProcessing} />

      <LoginModal
        show={component.loginModalState}
        onCancel={() => dispatch(toggleLoginModalVisibility(false))}
      />

      <header id="header" className="sticky-top">
        <div className="d-flex align-items-center justify-content-between bottom-bar">
          <div className="bottom-bar-left">
            <div className="logo">
              <Link
                to={localeRoutes.home[lang]}
                onClick={() => {
                  logEvent("Header", "logo_link_click", "Logo Link");
                }}
                className="cursor-pointer">
                <img src={logoSet[lang]} width="250px" height="100%" alt="autohub-logo" />
              </Link>
            </div>

            <nav
              id="navbar"
              className={
                navShow
                  ? "navbar order-last order-lg-0 navbar-mobile"
                  : "navbar order-last order-lg-0"
              }>
              <ul>
                <li className="mb-logo text-center">
                  <Link
                    to={localeRoutes.home[lang]}
                    onClick={() => {
                      logEvent("Header", "logo_link_click", "Logo Link");
                    }}
                    className="cursor-pointer">
                    <img src={logoSet[lang]} alt="autohub-logo" width="100%" height="100%" />
                  </Link>
                </li>
                {pages.includes("catalog") && (
                  <li className={`${pathname === localeRoutes.catalog[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.catalog[lang]}
                      onClick={() => {
                        logEvent("Header", "catalog_link_click", "calalog Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.catalog")}
                    </Link>
                  </li>
                )}
                {/* {pages.includes("services") && (
                  <li className={`dropdown dropdown-area ${inService}`}>
                    <span className="nav-link scrollto dropdown-toggle" onClick={toServices}>
                      {t("header.ourServices")}
                      <Icon iconName="caretDown" />
                    </span>
                    <ul className="dropdown-menu">
                      {services?.map(({ _id, name }, i) => {
                        return (
                          <Link
                            key={i}
                            onClick={() => {
                              logEvent("Header", "service_link_click", `Service ID: ${_id} Link`);
                            }}
                            to={`${localeRoutes.services[lang]}/${_id}`}
                            className={`dropdown-item ${pathname.includes(_id)}`}>
                            {!isLtr() ? name.value_ar : name.value_en}
                          </Link>
                        );
                      })}
                    </ul>
                  </li>
                )} */}
                {pages.includes("offers") && (
                  <li className={`dropdown dropdown-area ${inOffer}`}>
                    <span className="nav-link scrollto dropdown-toggle" onClick={toOffers}>
                      {t("header.offers")}
                      <Icon iconName="caretDown" />

                      {/* {isOfferHasNewDeal && (
                        <span className="offersNewTag headerNewTag">{t("offer.new")}</span>
                      )} */}
                    </span>
                    <ul className="dropdown-menu">
                      {slicedServiceCategories?.map(({ _id, name, slugUrl }, i) => {
                        const localeName = name["value_" + lang];
                        const query = i === 0 ? "" : `/${slugUrl}`;
                        return (
                          <Link
                            key={_id}
                            onClick={() => {
                              logEvent(
                                "Header",
                                "offer_link_click",
                                `Category name: ${localeName}`
                              );
                            }}
                            to={localeRoutes.offers[lang] + query}
                            className="dropdown-item">
                            {localeName}
                            {/* {isNewDeal && <span className="offersNewTag">{t("offer.new")}</span>} */}
                          </Link>
                        );
                      })}
                      {serviceCategories?.length > 5 ? (
                        <Link
                          to={localeRoutes.offers[lang]}
                          onClick={() => {
                            logEvent("Header", "offer_link_click", "Offer Link");
                          }}
                          className="dropdown-item">
                          {t("header.seeMore")}
                        </Link>
                      ) : null}
                    </ul>
                  </li>
                )}
                {pages.includes("kaizen") && (
                  <li className={`${inKaizen} header-menu`}>
                    <Link
                      to={localeRoutes.kaizen[lang]}
                      onClick={() => {
                        logEvent("Header", "kaizen_link_click", "Kaizen Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.kaizen")}
                    </Link>
                  </li>
                )}
                {pages.includes("blogs") && (
                  <li className={`${knowledgeHub} header-menu`}>
                    <Link
                      to={localeRoutes.knowledgeHub[lang]}
                      onClick={() => {
                        logEvent("Header", "knowledgehub_link_click", "Knowledgehub Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.knowledgehub")}
                    </Link>
                  </li>
                )}
                {pages.includes("location") && (
                  <li className={`${pathname === localeRoutes.locations[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.locations[lang]}
                      onClick={() => {
                        logEvent("Header", "location_link_click", "Location Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.location")}
                    </Link>
                  </li>
                )}
                {pages.includes("contactus") && (
                  <li className={`dropdown dropdown-area ${contactUs.includes(pathname.slice(1))}`}>
                    <span className="nav-link scrollto dropdown-toggle">
                      {t("header.contactus")}
                      <Icon iconName="caretDown" onClick={(e) => toContactUs({ e })} />
                    </span>
                    <ul className="dropdown-menu">
                      <Link
                        to={localeRoutes.customerCare[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.customerCare[lang]}`}>
                        {t("header." + camelize(contactUs[0].replaceAll("-", " ")))}
                      </Link>
                      <Link
                        to={localeRoutes.becomeAPartner[lang]}
                        className={`dropdown-item ${
                          pathname === localeRoutes.becomeAPartner[lang]
                        }`}>
                        {t("header." + camelize(contactUs[1].replaceAll("-", " ")))}
                      </Link>
                      <Link
                        to={localeRoutes.joinFamily[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.joinFamily[lang]}`}>
                        {t("header." + camelize(contactUs[2].replaceAll("-", " ")))}
                      </Link>
                      {/* <Link
                        to={localeRoutes.deleteAccount[lang]}
                        className={`dropdown-item ${pathname === localeRoutes.deleteAccount[lang]}`}>
                        {t("header." + camelize(contactUs[3].replaceAll("-", " ")))}
                      </Link> */}
                    </ul>
                  </li>
                )}
                {pages.includes("insurance") && (
                  <li className={`${pathname === localeRoutes.insurance[lang]} header-menu`}>
                    <Link
                      to={localeRoutes.insurance[lang]}
                      onClick={() => {
                        logEvent("Header", "insurance_link_click", "Insurance Link");
                      }}
                      className="nav-link scrollto">
                      {t("header.insurance")}
                    </Link>
                  </li>
                )}
                <li className="resp-bottom-bar-right">
                  <div className="bottom-bar-right">
                    {pages.includes("estimation") && (
                      <Button
                        type="small-roundedRight"
                        // customize="beta-tag"
                        action={() =>
                          navigationWithEvents(
                            "Header-Estimation",
                            "Estimation Welcome",
                            toEstimationWelcome
                          )
                        }>
                        {t("header.estimationRequest")}
                        {/* <span>{t("header.betaTag")}</span> */}
                      </Button>
                    )}
                    {pages.includes("quotation") && (
                      <Button
                        type="blue-roundedRight"
                        action={() =>
                          navigationWithEvents("Header-Quotation", "Quotation", toQuotations)
                        }>
                        {t("header.quotation")}
                      </Button>
                    )}
                    {pages.includes("schedule") && (
                      <Button
                        type="small-roundedRight"
                        action={() =>
                          navigationWithEvents(
                            "Header-Schedule-Appointment",
                            "Schedule Appointment",
                            toBringIt
                          )
                        }>
                        {t("header.bringItToTheExpert")}
                      </Button>
                    )}
                    {pages.includes("track") && (
                      <Button
                        type="blue-roundedRight"
                        action={() =>
                          navigationWithEvents(
                            "Header-Track",
                            "Track your progress",
                            toTrackYourProgress
                          )
                        }>
                        {t("header.trackYourRepairProgress")}
                      </Button>
                    )}
                    {pages.includes("eshop") && (
                      <Button
                        type="small-roundedRight"
                        action={() => navigationWithEvents("Header-Shop", "Shop", toShop)}>
                        {t("header.shop")}
                      </Button>
                    )}
                  </div>
                </li>
              </ul>
              <Icon
                iconName={navShow ? "xmark" : "bars"}
                className={"mobile-nav-" + `${navShow ? "close" : "toggle"}`}
                onClick={() => setNavShow(!navShow)}
              />
            </nav>
          </div>

          <div className="bottom-bar-right">
            {pages.includes("estimation") && (
              <Button
                type="small-roundedRight"
                customize="beta-tag"
                action={() =>
                  navigationWithEvents(
                    "Header-Estimation",
                    "Estimation Welcome",
                    toEstimationWelcome
                  )
                }>
                {t("header.estimationRequest")}
                {/* <span>{t("header.betaTag")}</span> */}
              </Button>
            )}
            {pages.includes("quotation") && (
              <Button
                type="blue-roundedRight"
                action={() => navigationWithEvents("Header-Quotation", "Quotation", toQuotations)}>
                {t("header.quotation")}
              </Button>
            )}
            {pages.includes("schedule") && (
              <Button
                type="small-roundedRight"
                action={() =>
                  navigationWithEvents(
                    "Header-Schedule-Appointment",
                    "Schedule Appointment",
                    toBringIt
                  )
                }>
                {t("header.bringItToTheExpert")}
              </Button>
            )}
            {pages.includes("track") && (
              <Button
                type="blue-roundedRight"
                action={() =>
                  navigationWithEvents("Header-Track", "Track your progress", toTrackYourProgress)
                }>
                {t("header.trackYourRepairProgress")}
              </Button>
            )}
            {pages.includes("eshop") && (
              <Button
                type="small-roundedRight"
                action={() => navigationWithEvents("Header-Shop", "Shop", toShop)}>
                {t("header.shop")}
              </Button>
            )}
          </div>

          {/* mobile responsive bottom nav */}
          <BottomTabNavigations />
        </div>
      </header>
    </>
  );
};

export default Header;
