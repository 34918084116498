import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, FlipCard } from "../shared";
import { QuotationService } from "../../services";

const Quotations = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getActiveQuotations();
  }, []);

  const getActiveQuotations = async () => {
    try {
      setLoading(true);
      const quotations = await QuotationService.getQuotations();
      setData(quotations);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader show={loading} />

      <section className="recent_main margin">
        <div className="container-fluid recent_main">
          <div className="recent_maincontent">
            <h2 className="mb-0">{t("track.quotations")}</h2>
          </div>
        </div>
      </section>

      <section className="pt-0 recent-offers margin">
        <div
          className="container-fluid recent_maincolor recent-offer-main pb-5"
          id="prepaid-services">
          {/* <h4>{t("offer.prePaidServices")}</h4> */}

          <div className="row paid-service-row">
            {data?.map((offer) => (
              <FlipCard
                key={offer?.id}
                offer={offer}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Quotations;
