import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { GET_MEDIA_URL } from "../../config/webService";
import { convertToSentenceCase, currentLanguage, getFormatedDate } from "../../util/common";
import { RiyalIcon, Icon, Button } from "../shared";

const CampaignViewModal = ({
  show,
  onCancel,
  offer,
  setLoading,
  goToBooking,
  AvailOfferButton
}) => {
  const { t } = useTranslation();
  const lang = currentLanguage();

  const handleImageLoad = () => setLoading(false);

  return (
    <Modal size="xl" show={show} onHide={onCancel} centered>
      <Modal.Body className="addon-desc-modal">
        <div className="close-icon">
          <Icon iconName="xmark" onClick={onCancel} />
        </div>

        <div className="content">
          <img
            src={
              GET_MEDIA_URL +
              (offer?.["campaignImage_" + lang] ||
                offer?.["campaignImage" + convertToSentenceCase(lang)])
            }
            onLoad={handleImageLoad}
          />
          <h2>{offer?.name?.["value_" + lang] || ""}</h2>
          <div className="addon-desc">
            <h5>{t("offersDetail.title")}</h5>
            <p
              className="shortDesc"
              dangerouslySetInnerHTML={{
                __html: offer.campaignTermsAndConditions?.["value_" + lang]
              }}
            />
          </div>
          <div className="button-and-expiry-wrap cart-btn w-100">
            <div>
              {AvailOfferButton || (
                <Button type="big-roundedRight" action={goToBooking}>
                  {t("offersDetail.bookAppointment")}
                </Button>
              )}
            </div>
            <div className="campaign-metadata">
              <div> 
                <div>
                  <span>{t("offersDetail.oldPrice")}</span>
                  <RiyalIcon />
                  <del className="fw-bold">{offer?.price}</del>
                </div>
                <div>
                  <span>{t("offersDetail.price")}</span>
                  <span className="fw-bold">
                    <RiyalIcon />
                    {offer?.campaignPrice}
                  </span>
                </div>
              </div>
              <div >
                <span>{t("offersDetail.campaignExpiryDate")}:</span>
                <span className="fw-bold">
                  {getFormatedDate(offer?.campaignEndDate, "MMMM DD, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignViewModal;
