import axios from "axios";
import BaseService from "../config/baseService";
import { API_GET_CAR_BRANDS, API_GET_CAR_MODELS, API_GET_CAR_YEARS } from "../config/webService";

class CarService extends BaseService {
  async getBrands({ serviceId, isSubscription }) {
    const response = await axios.get(API_GET_CAR_BRANDS, {
      params: {
        serviceId,
        isSubscription
      }
    });
    return response && response.data;
  }

  async getModels({ brand, serviceId, isSubscription }) {
    const response = await axios.get(API_GET_CAR_MODELS, {
      params: { brand, serviceId, isSubscription }
    });
    return response && response.data;
  }

  async getYears({ brand, model, serviceId, isSubscription }) {
    const response = await axios.get(API_GET_CAR_YEARS, {
      params: {
        brand,
        product: model,
        serviceId,
        isSubscription
      }
    });
    return response && response.data;
  }
}

export default new CarService();
