import axios from "axios";
import BaseService from "../config/baseService";
import {
  API_POST_ESTIMATION_REQUEST,
  API_GET_ESTIMATION_REQUEST,
  API_GET_ESTIMATION_REQUEST_BY_ID,
  API_UPDATE_ESTIMATION_REQUEST_COMMENTS,
  API_VALIDATE_ESTIMATION_CAR
} from "../config/webService";
import { createCarNickName, currentLanguage } from "../util/common";

class EstimationRequestService extends BaseService {
  constructor() {
    super();
  }
  async createEstimationRequest(values) {
    const payload = {
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.mobilePhone,
      make: values.Brand,
      model: values.Model,
      year: values.Year,
      otherBrand: values.OtherBrand,
      otherModel: values.OtherModel,
      licencePlate: values.plateNumber + values.plateChar,
      carNickName: createCarNickName({
        username: values.firstName,
        carNickName: values.carNickName,
        model: values.Model,
        otherModel: values.OtherModel
      }),
      howDidItHappen: values.HowHappen,
      whenDidItHappen: values.DamageMonth,
      damagedYear: values.DamageYear,
      frontImage: values.Front,
      rearImage: values.Rear,
      rightImage: values.Right,
      leftImage: values.Left,
      otherImages: JSON.stringify(values.otherImages),
      vehicleRegisterationCard: values.RegistrationCard,
      termsAccepted: values.Terms,
      City: values.City,
      Provider: values.Provider,
      paymentMethod: values.paymentMethod,
      insurancePdf: values.insurancePdf,
      branchId: values.branchId,
      language: currentLanguage()
    };

    const result = await axios.post(API_POST_ESTIMATION_REQUEST, payload);
    return result && result.data.response;
  }

  async updateEstimationRequest(values, id) {
    const payload = {
      id: id,
      howDidItHappen: values.HowHappen,
      whenDidItHappen: values.DamageMonth,
      damagedYear: values.DamageYear,
      frontImage: values.Front,
      rearImage: values.Rear,
      rightImage: values.Right,
      leftImage: values.Left,
      otherImages: JSON.stringify(values.otherImages),
      vehicleRegisterationCard: values.RegistrationCard,
      language: currentLanguage()
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }

    const result = await axios.put(API_POST_ESTIMATION_REQUEST, formData);
    return result && result.data.response;
  }

  async getEstimationRequestById(id, appointment = false) {
    const result = await axios.get(`${API_GET_ESTIMATION_REQUEST_BY_ID}/${id}`, {
      params: {
        appointment
      }
    });
    return result && result.data.response;
  }

  async getEstimationRequest() {
    // let key = number ? `phoneNumber=${number}` : null;
    const result = await axios.get(`${API_GET_ESTIMATION_REQUEST}`);
    return result && result.data.response;
  }

  async updateComments(id, user) {
    const result = await axios.put(`${API_UPDATE_ESTIMATION_REQUEST_COMMENTS}`, { id, user });
    return result && result.data.response;
  }

  async validateCar(licencePlate) {
    const result = await axios.post(`${API_VALIDATE_ESTIMATION_CAR}`, { licencePlate });
    return result && result.data.response;
  }
}

export default new EstimationRequestService();
