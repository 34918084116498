import axios from "axios";
import {
  API_RIGESTER_USER,
  API_VERIFY_USER,
  API_GET_OFFER_FOR_SERVICE,
  API_CREATE_CONTACT_US_REQUEST,
  API_GET_SLOTS,
  API_GET_SERVICE_PROVIDERS_BY_SERVICES,
  API_GET_OCASSIONS,
  API_RECENT_CARS,
  API_ADD_USER_CAR,
  API_UPDATE_USER_CAR,
  API_DELETE_USER_CAR,
  API_UPDATE_USER_CONTACT_SETTINGS,
  API_DOWNLOAD_FINAL_INVOICE,
  API_DOWNLOAD_DEPOSIT_INVOICE,
  API_UPDATE_SEEN_STATUS,
  API_REFUND_ORDER,
  API_REFUND_ORDER_INVOICE,
  API_UPDATE_FAST_COLLECT_SEEN_STATUS,
  API_CUSTOMER_NOT_SATISFIED,
  API_GET_AVAILABLE_SLOTS_FOR_CAR_PICKUP,
  API_SCHEDULE_CAR_PICKUP,
  API_UPDATE_RECOMMENDED_OFFER_SEEN_STATUS,
  API_LOGOUT_USER,
  API_UPDATE_USER,
  API_USER_NOTIFICATIONS,
  API_DELETE_ACCOUNT,
  API_REQUEST_REFUND_ORDER,
  API_GENERATE_CAREEM_CODE,
  API_GET_ACTIVE_BRANCHES
} from "../config/webService";
import BaseService from "../config/baseService";
import {
  createCarNickName,
  loadStorage,
  dispatchStorage,
  deleteStorage,
  currentLanguage,
  getQueryData
} from "../util/common";
import moment from "moment";

class UserService extends BaseService {
  async registerUser({ title, firstName, lastName, phoneNumber, email }) {
    const source = getQueryData("source");
    const lang = currentLanguage();
    const request = {
      title,
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      phoneNumber,
      email,
      language: lang,
      source: source === "carhub" ? "Carhub (App link)" : "autohub"
    };
    const response = await axios.post(API_RIGESTER_USER, request);
    return response && response.data;
  }

  async updateUser({ title, firstName, lastName, phoneNumber, email }) {
    const lang = currentLanguage();
    const request = {
      title,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phoneNumber,
      email,
      loggedIn: true,
      language: lang
    };
    const response = await axios.put(API_UPDATE_USER, request);
    return response && response.data;
  }

  async verifyUser(code, phoneNumber) {
    const request = { password: code, phoneNumber: phoneNumber };
    const response = await axios.post(API_VERIFY_USER, request);
    const { token } = response.data.token;
    dispatchStorage("token", token);

    // const isLoggedIn = token && token !== "TEMP_TOKEN" ? true : false;
    // if (isLoggedIn) dispatchStorage("otp", { verified: true });

    if (loadStorage("session")?.expired) deleteStorage("session");

    return response && response.data;
  }

  async logout() {
    const response = await axios.get(API_LOGOUT_USER);
    return response && response.data;
  }

  async logOutUser() {
    if (localStorage.getItem("persist:root")) {
      localStorage.removeItem("@ah-ksa:otp");
      localStorage.removeItem("persist:root");
      dispatchStorage("token", "TEMP_TOKEN");
      dispatchStorage("session", { expired: true });
      window.location.reload();
    }
  }

  async deleteAccount() {
    const response = await axios.delete(API_DELETE_ACCOUNT);
    return response && response.data;
  }

  async saveUserCar(data) {
    const payload = {
      userId: data?.userId,
      brand: data.Brand,
      model: data.Model,
      otherBrand: data.OtherBrand,
      otherModel: data.OtherModel,
      year: data.Year,
      carNumberPlate: data.plateNumber + "-" + data.plateChar,
      carNickName: createCarNickName({
        username: data?.username || data.OtherBrand || data.Brand,
        carNickName: data.carNickName,
        model: data.Model,
        otherModel: data.OtherModel
      })
    };

    let response;
    if (!data._id) {
      response = await axios.post(API_ADD_USER_CAR, payload);
    } else {
      payload.userCarId = data._id;
      response = await axios.put(API_UPDATE_USER_CAR, payload);
    }
    return response && response.data;
  }

  async deleteUserCar(id, userId) {
    const payload = {
      userId,
      userCarId: id
    };
    const response = await axios.put(API_DELETE_USER_CAR, payload);
    return response && response.data;
  }

  async updateUserContactSettings(data) {
    const payload = {
      userId: data.userId,
      email: data.isEmail,
      sms: data.isSMS,
      isAllowedMarketingEmailSms: data.isAllowedMarketingEmailSms
    };
    const response = await axios.put(API_UPDATE_USER_CONTACT_SETTINGS, payload);
    return response && response.data;
  }

  async getUserNotifications() {
    const response = await axios.get(API_USER_NOTIFICATIONS);
    return response && response.data;
  }

  async contactUsRequest(payload) {
    const formData = new FormData();
    for (const key in payload) {
      if (payload[key]) formData.append(key, payload[key]);
    }

    const response = await axios({
      method: "post",
      url: API_CREATE_CONTACT_US_REQUEST,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    });

    return response && response.data;
  }

  async customerFeedback(data) {
    const result = await axios.put(API_CUSTOMER_NOT_SATISFIED, data);
    return result?.data?.response;
  }

  async getRecentCars({ userId, serviceId, isSubscription }) {
    const response = await axios.get(API_RECENT_CARS + userId, {
      params: {
        serviceId,
        isSubscription
      }
    });
    return response && response.data;
  }

  async getProviders(id, branches = []) {
    const result = await axios.get(API_GET_SERVICE_PROVIDERS_BY_SERVICES, {
      params: { id, branches }
    });
    return result?.data?.response;
  }

  async getActiveBranches(forWeb = false) {
    const result = await axios.get(API_GET_ACTIVE_BRANCHES, {
      params: { forWeb }
    });
    return result?.data?.response;
  }

  async getOfferForService(serviceId) {
    const result = await axios.get(`${API_GET_OFFER_FOR_SERVICE}?serviceId=${serviceId}`);
    return result?.data?.response;
  }

  async getSlots(branchId, serviceId, day) {
    let currentDay = new Date();
    currentDay = currentDay.toISOString();
    // var unixTimestamp = Math.floor(Date.now() / 1000) // 1293683278
    const currentTime = moment(currentDay).format("hh:mma");
    const result = await axios.get(API_GET_SLOTS, {
      params: {
        branchId,
        serviceId,
        date: day,
        currentDay,
        currentTime
      }
    });
    return result?.data?.response;
  }

  async updateFastCollectSeenStatus(params) {
    const result = await axios.put(API_UPDATE_FAST_COLLECT_SEEN_STATUS, params);
    return result?.data?.response;
  }

  async updateRecommendedOfferSeenStatus(offerIds) {
    const result = await axios.put(API_UPDATE_RECOMMENDED_OFFER_SEEN_STATUS, { offerIds });
    return result && result.data?.response;
  }

  async getOcassions(branchId) {
    const result = await axios.get(API_GET_OCASSIONS + branchId);
    return result?.data?.response;
  }

  async downloadFinalInvoice(id, type) {
    const response = await axios.get(API_DOWNLOAD_FINAL_INVOICE(id, type), {
      responseType: "blob"
    });

    return response.data;
  }

  async downloadDepositInvoice(id, type) {
    const response = await axios.get(API_DOWNLOAD_DEPOSIT_INVOICE(id, type), {
      responseType: "blob"
    });

    return response.data;
  }

  async requestRefundOrder(orderId) {
    const response = await axios.post(API_REQUEST_REFUND_ORDER, { id: orderId });
    return response.data;
  }

  async refundOrder(id, reason) {
    const response = await axios.put(API_REFUND_ORDER, { id, reason });
    return response.data;
  }

  async refundOrderInvoice(id, type) {
    const response = await axios.get(API_REFUND_ORDER_INVOICE(id, type), {
      responseType: "blob"
    });

    return response.data;
  }

  async updateSeenStatus(id) {
    const response = await axios.put(API_UPDATE_SEEN_STATUS, { id });
    return response.data;
  }

  async getSlotsForCarPickup(selector) {
    const result = await axios.get(API_GET_AVAILABLE_SLOTS_FOR_CAR_PICKUP, {
      params: selector
    });
    return result?.data?.response;
  }

  async scheduleCarCollection(payload) {
    const result = await axios.put(API_SCHEDULE_CAR_PICKUP, payload);
    return result?.data?.response;
  }

  async generateCareemCode() {
    const result = await axios.put(API_GENERATE_CAREEM_CODE);
    return result?.data;
  }
}

export default new UserService();
