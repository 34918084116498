import React from "react";
import { useLocation } from "react-router-dom";
import { currentLanguage } from "../../util/common";
import { useTranslation } from "react-i18next";
import { Icon } from "./";
import { langConfig } from "../../util/addonsUtils";
import { useSelector } from "react-redux";
import { useAppNavigation } from "../../hooks";
import "../eShop/styles/eshop.scss";

const EShopBreadCrumb = ({ paymentDisplay = null, onPayment }) => {
  const { t } = useTranslation();
  const { localeRoutes, toShop, toShopCheckout } = useAppNavigation();
  const location = useLocation();
  const cartItems = useSelector((state) => state.cart.items);

  const lang = currentLanguage();
  const pathname = location.pathname;

  const goToShopCheckout = () =>
    cartItems.length && !onPayment
      ? toShopCheckout()
      : cartItems.length && onPayment
        ? paymentDisplay
        : null;

  return (
    <div className="shop-nav">
      <div className="container-fluid">
        <ul>
          <li
            className={pathname.includes(localeRoutes.shopProduct[lang]) ? "active" : ""}
            onClick={toShop}>
            <Icon iconName="shop" />
            <h6>{t("shop.shopNavLabel")}</h6>
          </li>
          <li className="indicator">{langConfig.breadCrumbIcon[lang]}</li>
          <li
            className={pathname.includes(localeRoutes.shopCheckout[lang]) && !onPayment ? "active" : ""}
            onClick={goToShopCheckout}>
            <Icon iconName="receipt" />
            <h6>{t("shop.orderSummaryNavLabel")}</h6>
          </li>
          <li className="indicator">{langConfig.breadCrumbIcon[lang]}</li>
          <li className={onPayment ? "active" : ""} onClick={paymentDisplay}>
            <Icon iconName="pay" />
            <h6>{t("shop.payNavLabel")}</h6>
          </li>
          <li className="indicator">{langConfig.breadCrumbIcon[lang]}</li>
          <li>
            <Icon iconName="shopbag" />
            <h6>{t("shop.collectNavLabel")}</h6>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default React.memo(EShopBreadCrumb);
