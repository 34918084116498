const RiyalIcon = ({ style = { color: "black" } }) => {
  return (
    <>
      {" "}
      <svg
        style={{ fill: "currentColor", ...style }}
        className="riyal-img"
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M609.47,885.89L609.47,885.89c-15.97,35.4-26.52,73.82-30.57,114.11l337.89-71.83c15.97-35.4,26.51-73.82,30.57-114.11L609.47,885.89z" />
        <path d="M916.79,713c15.97-35.4,26.52-73.82,30.57-114.11l-263.2,55.98V547.26l232.63-49.44c15.97-35.4,26.52-73.82,30.57-114.11l-263.2,55.93V52.63c-40.33,22.64-76.14,52.79-105.26,88.34v321.04l-105.26,22.37V0c-40.33,22.64-76.15,52.79-105.26,88.34v418.41L132.84,556.8c-15.97,35.4-26.52,73.82-30.57,114.11l266.1-56.55v135.52L83.19,810.49c-15.97,35.4-26.52,73.82-30.57,114.11l298.51-63.44c24.3-5.05,45.18-19.42,58.77-39.19l54.75-81.16l0.01-0.01c5.67-8.4,8.99-18.52,8.99-29.42V592l105.26-22.37v215.22L916.79,713z" />
      </svg>{" "}
    </>
  );
};

export default RiyalIcon;
